<template>
  <div id="cgu_poplab">
    <CguPopLabDe v-if="country === 'de'" />
    <CguPopLabFr v-else />
  </div>
</template>

<script>
import CguPopLabFr from "@/components/legal/poplab/CguPopLabFr.vue";
import CguPopLabDe from "@/components/legal/poplab/CguPopLabDe.vue";

import { mapGetters } from "vuex";

/**
 * Affiche les CGU de PopLab dans la bonne langue.
 */
export default {
  name: "CguPopLab",
  components: {
    CguPopLabFr,
    CguPopLabDe,
  },
  computed: {
    ...mapGetters(["country"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>
