<template>
  <div
    id="cgu_poplab_fr"
    class="text-regular"
  >
    <p class="bloc bold">
      Date&nbsp;: 20/09/2021
    </p>

    <div class="bloc">
      <h2 class="s4 light">
        Définitions
      </h2>
      <p>
        Les Parties conviennent et acceptent que les termes suivants utilisés avec une majuscule, au singulier et/ou au
        pluriel, auront, dans le cadre des présentes Conditions Générales d’Utilisation, la signification définie
        ci-après&nbsp;:
      </p>
      <ul>
        <li>
          "Site" désigne la plateforme numérique du type site Web permettant l’accès au Service ainsi que son
          utilisation
        </li>
        <li>"Utilisateur" désigne toute personne/internaute qui se connecte au Site</li>
      </ul>
      <p>
        Les présentes conditions générales d’utilisation (ci-après, les "CGU" ont pour objet de définir les conditions
        dans lesquelles, LDE (au capital de 500&nbsp;000,00&nbsp;€, immatriculée au RCS de Strasbourg sous le
        numéro 395 135 999) consent à un Utilisateur un droit d’utilisation, incessible et non exclusif sur les
        informations publiées, le (ou les) document(s) numérique(s), service(s) accessibles sur le site internet de
        PopLab (ci-après, le "Site") pour le monde entier.
      </p>
      <p>
        L’acceptation des présentes conditions générales d’utilisation se matérialise notamment par l’usage du Site.
      </p>
      <p>
        De plus, toute inscription ou utilisation du Site implique l’acceptation sans aucune réserve ni restriction des
        présentes CGU par l’Utilisateur. Lors de la création de compte par l’Utilisation, chaque Utilisateur accepte
        expressément les présentes CGU.
      </p>
      <p>
        PopLab se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU, dans ce
        cas une nouvelle demande d’acceptation sera présentée à l’Utilisateur.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 1&nbsp;: Accès au Site
      </h2>

      <h3 class="s6">
        A) Acceptation
      </h3>
      <p>
        En naviguant sur le Site et plus précisément lors de la création d’un compte, l’Utilisateur doit "accepter" les
        CGU lorsqu’elles lui sont soumises pour acceptation. L’Utilisateur doit accepter, sans réserve, les CGU. Les CGU
        sont consultables à tout moment sur le Site et sont communiquées à première demande. L’Utilisateur déclare avoir
        la pleine capacité juridique lui permettant de s’engager au titre des présentes CGU.
      </p>

      <h3 class="s6">
        B) Documents contractuels
      </h3>
      <p>
        Les CGU définissent les principales règles d’utilisation du Site. Elles sont complétées par la Charte de
        protection des données à caractère personnel afin de guider plus précisément l’Utilisateur dans l’utilisation du
        Site.
      </p>
      <p>
        Dans l’hypothèse d’un conflit entre les différents documents contractuels, les présentes CGU priment sur les
        autres documents contractuels.
      </p>

      <h3 class="s6">
        C) Autonomie des stipulations
      </h3>
      <p>
        Dans l’hypothèse où l’une des stipulations des présentes CGU serait déclarée nulle, inapplicable ou inopposable
        par l’effet d’une loi, d’un règlement ou d’une décision émanant d’une juridiction compétente, seule cette
        stipulation sera écartée tandis que les autres stipulations conserveront leur validité et leur force, sauf à
        imaginer que les présentes CGU soient dénaturées ou que cela entraîne un déséquilibre significatif des
        obligations à la charge des parties.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 2&nbsp;: Essai gratuit
      </h2>
      <p>
        PopLab propose des essais gratuits et limités dans le temps, de différentes fonctionnalités du Site. PopLab se
        réserve le droit de déterminer l’éligibilité de tout Utilisateur à un essai et de retirer ou de modifier un
        essai à tout moment, sans préavis, ni aucune responsabilité.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 3&nbsp;: Disponibilité du site internet
      </h2>
      <p>
        PopLab s’efforce d’assurer la disponibilité du Site 24h/24h et 7j/7. Cependant, l’accès au Site peut être
        interrompu notamment dans le cadre d’opérations de maintenance, de mises à niveau ou de mises à jour, de
        réparations d’urgence, ou par suite de circonstances indépendantes de la volonté de PopLab.
      </p>
      <p>
        Toutefois, PopLab s’engage à faire de la disponibilité de son Site une priorité et à informer l’Utilisateur de
        toute opération de maintenance programmée.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 4&nbsp;: Obligations de l’Utilisateur
      </h2>
      <p>
        PopLab s’assure de la comptabilité de son environnement technique avec le Site. En outre, l’Utilisateur est tenu
        d’effectuer des sauvegardes régulières des données utiles à ses activités et des informations enregistrées lors
        de l’utilisation du Site.
      </p>
      <p>
        Les noms d’Utilisateur et les mots de passe utilisés et/ou communiqués à l’Utilisateur sont personnels,
        confidentiels et non-transmissibles. L’usage de ces éléments d’authentification est placé sous la seule
        responsabilité de l’Utilisateur. Ledit Utilisateur s’interdit de transmettre ou de communiquer ces éléments à
        des tiers non autorisés, s’engage à avertir sans délai PopLab par écrit en cas de perte ou de vol de ces
        éléments.
      </p>
      <p>
        L’Utilisateur s’engage à informer, sans délai et par écrit, PopLab de toutes modifications des informations le
        concernant et/ou ayant une incidence ou susceptibles d’avoir une incidence sur le périmètre de la licence
        d’utilisation concédée.
      </p>
      <p>
        PopLab se réserve le droit, en cas de non-respect des présentes, de couper l’accès de l’Utilisateur au Site.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 5&nbsp;: Responsabilité
      </h2>

      <h3 class="s6">
        A) Responsabilité générale
      </h3>
      <p>L’éditeur n’’est responsable que du contenu qu’il a lui-même édité.</p>
      <p>
        PopLab ne peut être tenu responsable dans le cas où l'inexécution ou la mauvaise exécution du contrat est
        imputable, soit à l'Utilisateur, soit au fait, imprévisible et insurmontable, d'un tiers étranger à la
        fourniture des prestations prévues au contrat, soit à un cas de force majeure et notamment en cas de défaillance
        des réseaux de communications électroniques, de ralentissement ou d’engorgement du réseau internet ou du réseau
        de l’Utilisateur, de pannes, interruptions ou du mauvais fonctionnement des services du fournisseur d’accès à
        internet de l’Utilisateur, de contamination par un virus informatique, d’acte de piratage, d’intrusion
        frauduleuse ou d’accès ou d’intervention non autorisé d’un tiers, et plus généralement de tout acte volontaire
        de dégradation, malveillance, sabotage ou détérioration. PopLab ne saurait être tenue responsable de tout défaut
        de fonctionnement du terminal de l’Utilisateur.
      </p>
      <p>Dans les limites citées ci-dessus, PopLab ne sera pas responsable pour&nbsp;:</p>
      <ul>
        <li>
          tout dommage indirect résultant d’un manquement à ses obligations, ce qui inclut notamment toute perte
          d’opportunités d’affaires, pertes de revenus, de recettes, de profits ou d’économies, perte de relations
          contractuelles, perte de réputation etc.
        </li>
        <li>
          tout dommage, préjudice ou toute perte causée par des virus ou autre malware contenu dans ou connecté aux
          dossiers accessibles au téléchargement d’internet ou contenus issus d’internet partagés par un Utilisateur.
          Les Utilisateurs sont responsables de mettre en place des mesures de sécurité – tels que les anti-virus- et
          des firewalls pour prévenir une telle infection ou attaque et pour sécuriser les copies de sauvegarde de toute
          donnée échangée à travers ou téléchargée sur l’Application.
        </li>
      </ul>

      <h3 class="s6">
        B) Lien hypertexte
      </h3>
      <p>
        Le Site peut contenir des liens hypertextes portant vers d’autres sites internet sur lesquels PopLab n’exerce
        pas de contrôle. Malgré les vérifications préalables et régulières réalisées, PopLab décline toute
        responsabilité quant aux contenus qu’il est possible de trouver sur ces sites.
      </p>
      <p>PopLab autorise la mise en place de liens hypertextes vers toute page ou document de son site.</p>
      <p>
        Sont exclus de cette autorisation les sites diffusant des informations à caractère illicite, violent, polémique,
        pornographique, xénophobe ou pouvant porter atteinte à la sensibilité du plus grand nombre.
      </p>
      <p>
        PopLab se réserve le droit de supprimer à tout moment un lien hypertexte pointant vers son Site, si le site
        l’estime non conforme à sa politique éditoriale.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 6&nbsp;: Protection des données à caractère personnel
      </h2>
      <p>
        PopLab accorde une grande importance à la protection des données à caractère personnel de ses partenaires et de
        ses utilisateurs. C’est pourquoi une Charte de protection des données a été mise en place. La Charte pourra être
        modifiée à tout moment par PopLab, au gré des modifications effectuées, afin notamment de se conformer à toutes
        évolutions réglementaires, jurisprudentielles, éditoriales et techniques. Il est conseillé de consulter la
        Charte avant toute navigation et utilisation du Site.
      </p>
      <p>
        PopLab respecte le Règlement n°2016-679 du 27 avril 2016 relatif à la protection des personnes physiques à
        l'égard du traitement des données à caractère personnel et à la libre circulation de ces données (ci-après,
        "RGPD"), en complément de la loi n°78-17 du 6 janvier 1978 modifiée relative à l'informatique, aux fichiers et
        aux libertés (dite loi "Informatique et libertés") ainsi que la loi n° 2004-575 du 21 juin 2004 pour la
        confiance dans l’économie numérique. Avec cette Charte PopLab souhaite renforcer sa politique de protection des
        données personnelles afin que les données de l’Utilisateur soient collectées et utilisées de manière
        transparente, confidentielle et sécurisée.
      </p>
      <p>
        Les données à caractère personnel font l’objet d’un traitement informatique par LDE agissant en qualité de
        responsable de traitement dont la finalité du traitement est prévue dans la Charte.
      </p>
      <p>
        Les données à caractère personnel sont conservées pendant la durée strictement nécessaire à l’accomplissement
        des finalités rappelées au sein de la Charte.
      </p>
      <p>
        Conformément à la réglementation en vigueur les Utilisateurs de notre plateforme disposent des droits
        suivants&nbsp;:
      </p>
      <ul>
        <li>droit d'accès&nbsp;;</li>
        <li>
          droit de rectification si vos données sont erronées ou obsolètes (mise à jour et complétude)&nbsp;;
        </li>
        <li>droit à l’oubli pour effacer vos données&nbsp;;</li>
        <li>droit de retirer à tout moment un consentement&nbsp;;</li>
        <li>droit à la limitation du traitement&nbsp;;</li>
        <li>droit d'opposition aux traitement des données personnelles&nbsp;;</li>
        <li>
          droit à la portabilité des données que vous avez fournies, lorsque ces données font l'objet de traitements
          automatisés fondés sur leur consentement ou un contrat&nbsp;;
        </li>
        <li>
          droit de définir le sort des données à caractère personnel à la mort d’un Utilisateur et de choisir à qui
          PopLab devra communiquer ses données (personne préalablement désignée).
        </li>
      </ul>
      <p>
        Pour une information complète sur le traitement des données à caractère personnel, il convient de se reporter à
        notre Charte de protection des données (mettre le lien pour aller à la Charte).
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 7&nbsp;: Propriété intellectuelle
      </h2>

      <h3 class="s6">
        A) Titulaire
      </h3>
      <p>
        L’ensemble du Site est régi par la législation française sur le droit d’auteur et la propriété intellectuelle.
        Ainsi, tout le contenu présent sur le Site, incluant, de façon non limitative, les graphismes, les images, les
        textes, les vidéos, les animations, les sons, les logos, les gifs et les icônes ainsi que leur mise en forme
        sont la propriété exclusive de PopLab à l'exception des marques, logos ou contenus appartenant à d'autres
        sociétés partenaires ou auteurs.
      </p>
      <p>La marque PopLab est une marque déposée.</p>
      <p>
        Le Site dans son ensemble ainsi que les éléments qui le composent (textes, arborescences, illustration,
        représentations graphiques, logo, etc...) constituent une œuvre de l’esprit protégés par les articles L.111-1 et
        suivants du code de la propriété intellectuelle.
      </p>
      <p>
        Ils sont la propriété exclusive de PopLab, seule habilitée à utiliser lesdits droits de propriété intellectuelle
        ainsi que les droits de la personnalité, y afférents, les œuvres de l’esprit, les bases de données, les image.
      </p>
      <p>
        Ainsi, toute représentation et/ou reproduction et/ou diffusion et/ou exploitation partielle ou totale des
        contenus proposés dans/par PopLab tels que les logiciels, les animations, les textes, les photos, les vidéos, le
        graphisme et plus largement la structure générale, par quelque procédé que ce soit, sous quelque forme que ce
        soit, sur quelque support que ce soit et par quelque média que ce soit, est strictement interdite sans
        l’autorisation préalable expresse et écrite de PopLab et est passible de poursuites judiciaires.
      </p>
      <p>L’Utilisateur s’interdit, donc, de procéder à&nbsp;:</p>
      <ul>
        <li>
          toute reproduction provisoire ou permanente de tout ou partie du Site, par quelque moyen que ce
          soit&nbsp;;
        </li>
        <li>tout accès ou tentative d’accès aux applicatifs du Site&nbsp;;</li>
        <li>
          toute décompilation ou ingénierie inverse du Site notamment en vue de la création d’un service
          similaire&nbsp;;
        </li>
        <li>
          tout interfaçage ou intégration du Site et/ou de la Notation avec d’autres services ou logiciels sans
          autorisation écrite préalable de PopLab&nbsp;;
        </li>
        <li>
          toute diffusion, distribution, mise à disposition gratuite ou payante du Site au service d’une autre
          entreprise, du public ou de tiers (sauf en cas de simple mention de l’existence de Site avec un lien
          hypertexte redirigeant vers le Site, qui rappelle sans ambiguïté que PopLab en est l’éditeur)&nbsp;;
        </li>
        <li>toute adaptation ou modification du Site quelle qu’elle soit&nbsp;;</li>
        <li>
          toute introduction ou tentative d’introduction frauduleuse ou non autorisée au Site. De même, sont interdites
          l’extraction ou la réutilisation d’une partie qualitativement ou quantitativement substantielle des librairies
          propres au dispositif du Site.
        </li>
      </ul>
      <p>
        L’Utilisateur s’engage à ne pas développer, utiliser ou exploiter, à quelque titre que ce soit et dans quelque
        territoire que ce soit, des signes identiques ou similaires à PopLab, susceptibles de porter atteinte aux droits
        de propriété intellectuelle de PopLab.
      </p>
      <p>PopLab est titulaire de ses noms de domaine.</p>
      <p>
        L’Utilisateur s’engage à ne pas réserver de noms de domaine, dans quelque extension que ce soit, reprenant ou
        imitant les éléments verbaux du PopLab ou susceptibles de porter atteinte aux droits de propriété intellectuelle
        de PopLab.
      </p>
      <p>
        L’Utilisateur s’engage à ne pas utiliser PopLab à des fins politiques, polémiques, contraires à l’ordre public
        ou aux bonnes mœurs ou susceptibles de porter atteinte à des droits reconnus par la loi et, de manière générale,
        à ne pas associer PopLab à des actions ou activités susceptibles de porter atteinte ou d’être préjudiciable à
        PopLab, notamment à tout comportement pouvant être associé directement ou indirectement à des actes de
        contrefaçon ou de concurrence déloyale, dont le détournement de clientèle, le dénigrement ou les pratiques
        commerciales trompeuses.
      </p>
      <p>
        PopLab décline toute responsabilité pour toute demande, action ou réclamation introduite par un tiers à raison
        des propos, actions ou omissions d’un Utilisateur en violation avec ces restrictions d’utilisation.
      </p>

      <h3 class="s6">
        B) Sanctions
      </h3>
      <p>
        L’usage de tout ou partie du Site, notamment par reproduction, transmission ou diffusion à d’autres fins que
        pour l’usage personnel et privé dans un but non commercial de l’utilisateur est strictement interdit. La
        violation des droits de PopLab expose son auteur aux sanctions prévues par le code de la propriété
        intellectuelle au titre notamment de la contrefaçon de droit d’auteur (article L.335-1 et suivants), de droit de
        marque (article L. 716-1 et suivants) que par le Code civil en matière de responsabilité civile (article 9,
        articles 1382 et suivants).
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 8&nbsp;: Loi applicable et règlement des litiges
      </h2>

      <h3 class="s6">
        A) Résolution à l’amiable de différends
      </h3>
      <p>
        Les Utilisateurs sont invités à contacter PopLab aux coordonnées fournies à l’article 10 des CGU afin de tenter
        de résoudre tous litiges et difficultés éventuels.
      </p>
      <p>L’Utilisateur peut soumettre une plainte comprenant une brève description.</p>
      <p>PopLab traitera la plainte au plus tard dans un délai de 15 jours après sa réception.</p>

      <h3 class="s6">
        B) Résolution de conflits en ligne
      </h3>
      <p>
        La Commission Européenne a établi une plateforme en ligne pour les règlements de conflits alternatifs qui
        facilite une méthode extrajudiciaires liés aux contrats de ventes et de services en ligne.
      </p>
      <p>
        C’est pourquoi, tout Consommateur Européen peut utiliser une telle plateforme pour résoudre toute dispute venant
        de contrats qui ont été conclus en ligne.
      </p>

      <h3 class="s6">
        C) Droit applicable
      </h3>
      <p>
        Sauf en cas d’application d’une règle d’ordre public, les présentes CGU sont régies par le droit français et
        tout litige relatif aux présentes et à l’utilisation de l’Application est soumis à la compétence exclusive des
        tribunaux du ressort de la Cour d’Appel de Paris.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 9&nbsp;: Divers
      </h2>

      <h3 class="s6">
        A) Clause de non-renonciation
      </h3>
      <p>
        Le fait que PopLab ne fasse pas valoir un droit ou une disposition dans le cadre des présentes CGU ne saurait
        constituer une renonciation à ce droit ou cette disposition. Aucune renonciation ne saurait être considérée
        comme une renonciation supplémentaire ou continue à la présente condition ou à n’importe quelle autre.
      </p>

      <h3 class="s6">
        B) Mise à jour des CGU
      </h3>
      <p>PopLab se réserve le droit, à tout moment, de compléter ou de modifier les présentes CGU.</p>
      <p>
        L’Utilisateur sera informé de la mise à jour des CGU applicable à l’utilisateur dès son acceptation de cette
        nouvelle version.
      </p>
      <p>
        Il incombe à l’Utilisateur de lire attentivement les CGU lors de leur mise à jour et de prendre pleinement
        connaissance des modifications engendrées par cette dernière.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Article 10&nbsp;: Contact
      </h2>
      <address>
        PopLab peut être contacté par courrier au&nbsp;: 1 rue de Kyoto 67150 ERSTEIN<br />
        Numéro de téléphone&nbsp;: 03 88 39 17 07<br />
        Courriel&nbsp;: <a href="mailto:aide@poplab.education">aide@poplab.education</a>
      </address>
    </div>
  </div>
</template>

<script>
/**
 * Affiche la page des CGU de PopLab en français.
 */
export default {
  name: "CguPopLabFr",
};
</script>

<style lang="scss"></style>
