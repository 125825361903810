<template>
  <div
    id="cgu_poplab_de"
    class="text-regular"
  >
    <div class="bloc">
      <h2 class="s4 light">
        1: Geltungsbereich
      </h2>
      <p>
        Die LDE GmbH & Co. KG (im Folgenden: LDE) bietet Schulen, Lehrenden und Schü-lern/Kursteilnehmern (im Folgenden:
        Nutzer) die Nutzung der Plattform PopLab.schule (im Fol-genden: Plattform) für die Erstellung und Verbreitung
        von Unterrichtseinheiten an.
      </p>
      <p>
        Für die Geschäftsbeziehung zwischen der LDE und den Nutzern sowie für die Nutzung der von LDE angebotenen
        digitalen Plattform PopLab.schule gelten ausschließlich nachfolgenden Allge-meinen Geschäftsbedingungen in ihrer
        zum Zeitpunkt des Vertragsschlusses bzw. der Nutzung gültigen Fassung. Abweichende Bedingungen werden nicht
        anerkannt, es sei denn, LDE hat aus-drücklich ihrer Geltung zugestimmt.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        2: Identität des Anbieters
      </h2>
      <p>Die Nutzung der Plattform durch in Deutschland ansässige Nutzer wird angeboten von der</p>
      <address>
        LDE GmbH & Co.K<br />
        Robert-Koch-Straße 35<br />
        77694 Kehl<br />
        Handelsregister: AG Freiburg HRA 370799
      </address>
      <p>vertreten durch</p>
      <address>
        LDE Verwaltungs GmbH, Robert-Koch-Straße 35<br />
        77694 Kehl<br />
        Handelsregister: AG Freiburg HRB 372026<br />
        vertretendurch den Geschäftsführer: Frédéric FRITSCH<br />
        Tel.: +49 (0)7851 - 48 599-0<br />
        Fax +49 (0)7851-48 599-22<br />
        E-Mail: <a :href="`mailto:${mailLdeDe}`">{{ mailLdeDe }}</a>.
      </address>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        3: Identität des Anbieters
      </h2>

      <h3 class="s6">
        A) Definition der Nutzergruppen
      </h3>
      <p>
        Die Nutzer der Plattform unterteilen sich in Vertragspartner, Lehrende, die keine Vertragspartner sind, und
        Schüler.
      </p>

      <h4>
        1) Vertragspartner
      </h4>
      <p>
        LDE schließt mit Schulen und anderen Bildungseinrichtungen einschließlich privater Unterneh-men des
        Bildungssektors sowie mit selbständig tätigen Lehrenden Verträge über die Nutzung der Plattform. Diese Verträge
        ermöglichen die Nutzung der Plattform durch die Vertragspartner selbst sowie durch die für sie tätigen Lehrenden
        und die von ihnen unterrichteten Schüler. Verträge mit Verbrauchern i.S.d. § 13 BGB (natürliche Person, die ein
        Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen
        beruflichen Tätigkeit zuge-rechnet werden können) werden nicht geschlossen.
      </p>

      <h4>
        2) Lehrende, die keine Vertragspartner sind
      </h4>
      <p>
        Die mit den Vertragspartnern (§ 3.1.1) von LDE geschlossenen Verträge ermöglichen den bei oder für einem
        Vertragspartner tätigen Lehrenden die Nutzung der Plattform zu Unterrichtszwe-cken, insbesondere zum Erstellen
        von Unterrichtseinheiten und -Materialien zwecks Nutzung durch Schüler (§ 3.1.3). LDE kann – ohne dass ein
        Rechtsanspruch darauf bestünde – einzelnen Lehrenden, die nicht für einen Vertragspartner tätig sind, eine
        zeitlich befristete unentgeltliche Nutzung (kostenlose Testphase) ermöglichen.
      </p>

      <h4>
        3) Schüler
      </h4>
      <p>
        Natürliche Personen, die weder Vertragspartner noch Lehrende sind, können ein kostenloses Nutzerkonto für die
        Plattform anlegen, um die für sie durch Vertragspartner bzw. Lehrende frei-gegebenen Inhalte zu nutzen. Das
        Einstellen von Inhalten ist für Schüler ausschließlich im Rah-men der jeweiligen Freigabe durch
        Vertragspartner/Lehrende innerhalb des von ihnen verwalteten Bereichs möglich.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        4: Vertragsschluss, Preise
      </h2>
      <p>
        An einem Vertragsschluss interessierte Stellen oder Personen nach § 3.1.1 (Schulen und anderen
        Bildungseinrichtungen einschließlich privater Unternehmen des Bildungssektors sowie selbstän-dig tätige
        Lehrende) können LDE über die Plattform PopLab.schule, E-Mail oder über sonstige Kommunikationswege ein
        verbindliches Angebot auf Abschluss eines Vertrages übermitteln. Der Interessent erhält per E-Mail eine
        Eingangsbestätigung mit den von ihm übermittelten Daten. Die-se Eingangsbestätigung stellt keine Annahme des
        Angebots dar, sondern dient der Information des Interessenten über den Eingang seines Vertragsangebots bei LDE.
        Der Vertrag kommt zu-stande, wenn LDE das Vertragsangebot ausdrücklich annimmt. Soweit erforderlich, fordert LDE
        beim Interessenten die für einen Vertragsschluss erforderlichen Nachweise, dass er nicht als Verbraucher i.S.d.
        § 13 BGB handelt, an. Kann LDE das Angebot des Kunden nicht annehmen, wird der Interessent hierüber informiert.
        Es gelten die jeweils aktuellen Preislisten.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        5: Zahlungsbedingungen, Verzug
      </h2>
      <p>
        Die Kosten für die Nutzung werden entsprechend dem jeweiligen Vertrag monatlich abgerechnet und sind, sofern in
        der Rechnung nicht anders angegeben, sofort nach Rechnungsstellung ohne Skonto fällig. Skontoabzüge werden nicht
        anerkannt und werden nachgefordert.
      </p>
      <p>
        Bei Zahlungsverzug ist LDE berechtigt, Verzugszinsen in Höhe von jährlich 9% über dem von der Europäischen
        Zentralbank bekanntgegebenen Basiszinssatz zu fordern.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        6: Anmeldung von Lehrenden, die keine Vertragspartner sind
      </h2>
      <p>
        Lehrende, die keine Vertragspartner sind (§ 3.1.2), können sich online auf der Plattform anmel-den. Zur
        Anmeldung als Lehrender ist eine Bestätigung des Vertragspartners, für den der Leh-rende tätig ist,
        erforderlich, sofern der Lehrende sich nicht für eine kostenlose Testphase anmel-det. Die Bestätigung des
        Vertragspartners kann im Rahmen der Anmeldung übermittelt oder nach Erhalt der Eingangsbestätigung für die
        Anmeldung eingereicht werden. Die Freischaltung durch LDE erfolgt, sobald diese Bestätigung vorliegt oder dem
        Lehrenden eine kostenlose Testphase gestattet wurde.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        7: Anmeldung von Schülern
      </h2>
      <p>
        Schüler (§ 3.1.3) können sich online auf der Plattform anmelden. Die Nutzung der Plattform als Schüler ist auf
        die für den jeweiligen Schüler durch Vertragspartner oder Lehrende freigegebene Inhalte beschränkt.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        8: Kündigung von Verträgen/Ende der Mitgliedschaft
      </h2>

      <h3 class="s6">
        A)
      </h3>
      <p>
        Die mit Vertragspartnern geschlossenen Verträge können von beiden Seiten mit einer Frist von dreißig (30) Tagen
        zum Monatsende kündigen. Die Kündigung bedarf der Schriftform. Das Recht zur Kündigung aus wichtigem Grund ist
        hiervon nicht berührt.
      </p>

      <h3 class="s6">
        B)
      </h3>
      <p>
        Lehrende und Schüler können ihre Mitgliedschaft jederzeit online über die Plattform sowie per E-Mail, Telefax
        oder Brief beenden. Bei Vorliegen eines wichtigen Grundes oder einer ent-sprechenden Rechtspflicht oder
        gerichtlichen Entscheidung kann LDE den Zugang von Lehren-den und Schülern sperren oder die Mitgliedschaft
        beenden.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        9: Inhalte, Urheberrecht, Nutzungsrechte
      </h2>

      <h3 class="s6">
        A) Bereitstellung von Inhalten durch LDE
      </h3>
      <p>
        Soweit LDE über die Plattform Texte, Bilder, Audio- und Videoaufnahmen oder andere Inhalte zur Verfügung stellt,
        dürfen diese ausschließlich auf der Plattform, insbesondere als Unterrichts-materialien oder zu deren Erstellung
        durch Vertragspartner und Lehrende, verwendet werden. Die Weitergabe der Inhalte und der mit ihnen erstellten
        Unterrichtsmaterialien sowie deren Verwen-dung außerhalb der Plattform bedürfen, soweit sie nicht ausdrücklich
        aufgrund eines Gesetzes zulässig sind, der vorherigen schriftlichen Einwilligung von LDE.
      </p>

      <h3 class="s6">
        B) Inhalte von Vertragspartnern und Lehrenden
      </h3>
      <p>
        Die von Vertragspartnern und Lehrenden auf der Plattform eingestellten Inhalte dürfen von LDE den Schülern, für
        die die Vertragspartner und Lehrenden die Inhalte freigegeben haben, über die Plattform zugänglich gemacht
        werden. Eine darüberhinausgehende Nutzung oder Verbreitung der Inhalte durch LDE erfolgt nicht. Die
        Vertragspartner und Lehrenden sichern LDE zu, über die zur Nutzung der von ihnen eingestellten Inhalte und deren
        Verbreitung über die Plattform erfor-derlichen Rechte zu verfügen und erteilen LDE zu diesem Zweck für die Dauer
        der Vertragsbe-ziehung des Vertragspartners bzw. der Mitgliedschaft des Lehrenden unentgeltlich das nicht
        aus-schließliche, geographisch und inhaltlich unbeschränkte, übertragbare und unterlizenzierbare Recht, die
        Inhalte auf der Plattform im Rahmen der Freigabe durch den Vertragspartner bzw. Leh-renden zugänglich zu machen,
        zu vervielfältigen, zu verbreiten und zu bearbeiten.
      </p>

      <h3 class="s6">
        C) Nutzung durch Schüler
      </h3>
      <p>
        Schüler können die für sie freigeschalteten Inhalte zu Lernzwecken, insbesondere zur Vor- und Nachbereitung des
        Unterrichts, zur Teilnahme am Unterricht, für Hausaufgaben und zur Vorbe-reitung von Referaten, nutzen.
      </p>

      <h3 class="s6">
        D) Anfertigung von Kopien
      </h3>
      <p>
        Eine Verlinkung zu auf der Plattform hinterlegten Inhalten bedarf der vorherigen schriftlichen Zustimmung durch
        LDE.
      </p>

      <h3 class="s6">
        E) Inhalte von Vertragspartnern und Lehrenden
      </h3>
      <p>
        Die Anfertigung von Kopien (z.B. digitale Kopien, Ausdrucke, Fotokopien) ist, soweit es nicht ausdrücklich vom
        Gesetz zugelassen ist, nicht gestattet.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        10: Verantwortung für Inhalte, Haftungsfreistellung, Löschung, Sicherung
      </h2>

      <h3 class="s6">
        A)
      </h3>
      <p>
        Die Vertragspartner und Lehrenden sind für die von Ihnen eingestellten und/oder bearbeite-ten Inhalte
        verantwortlich. Sie stellen LDE von allen Ansprüchen frei, die Dritte wegen der Ver-letzung ihrer Rechte durch
        von ihnen eingestellte Inhalte geltend machen (z.B. Verletzung von Urheberrechten). Die Vertragspartner und
        Lehrenden stellen LDE von den Kosten der notwendi-gen Rechtsverfolgung einschließlich sämtlicher Gerichts- und
        Anwaltskosten frei. Dies gilt nicht, soweit die Rechtsverletzung nicht vom jeweiligen Vertragspartner bzw.
        Lehrenden zu vertreten ist.
      </p>

      <h3 class="s6">
        B)
      </h3>
      <p>
        Wenn konkrete Ansatzpunkte dafür bestehen, dass von Vertragspartnern oder Lehrenden eingestellte Inhalte oder
        von ihnen vorgenommene Bearbeitungen gegen gesetzliche Vorschriften, Rechte Dritter oder diesen Vertrag
        verstoßen, ist LDE berechtigt, diese Inhalte zu löschen. LDE informiert den betroffenen Vertragspartner oder
        Lehrenden sofort über die Löschung von Ange-boten.
      </p>

      <h3 class="s6">
        C)
      </h3>
      <p>Vertragspartner und Lehrende können die von Ihnen eingestellten Inhalte jederzeit löschen.</p>

      <h3 class="s6">
        D)
      </h3>
      <p>
        Endet die Vertragsbeziehung des Vertragspartners bzw. die Mitgliedschaft des Lehrenden, werden die von Ihnen
        erstellten Inhalte durch LDE gelöscht. Es ist Sache der Vertragspartner und Lehrenden, ihre Inhalte vor
        Vertragsende zu sichern.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        11: Widerrufsrecht
      </h2>
      <p>
        Entgeltliche Verträge über die Nutzung der Plattform werden ausschließlich mit Vertragspartnern geschlossen, die
        nicht Verbraucher i.S.d. § 13 BGB sind und denen daher kein gesetzliches Wi-derrufsrecht zusteht.
      </p>
      <p>
        Verbraucher, die die Plattform als Lehrende oder Schüler nutzen, können ihre Mitgliedschaft jederzeit beenden.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        12: Technische Beschränkungen, Nutzungsvoraussetzungen, Verfügbarkeit, Wartung
      </h2>

      <h3 class="s6">
        A)
      </h3>
      <p>
        Die Nutzung digitaler Dienstleistungen kann von der Verwendung bestimmter Geräte und/oder Software und/oder
        technischen Mindestanforderungen an die zu verwendenden Geräte und Programme abhängen. Diese
        Nutzungsvoraussetzungen und etwaige LDE bekannte techni-sche Beschränkungen entnehmen Sie bitte der Beschreibung
        der Plattform.
      </p>

      <h3 class="s6">
        B)
      </h3>
      <p>
        LDE ist bestrebt, die Plattform jederzeit zur Verfügung zu stellen. Da dies jedoch aus tech-nischen Gründen
        nicht immer möglich ist, gilt die Leistung von LDE als erbracht, wenn die Platt-form im Jahresdurchschnitt zu
        98% für die Nutzung digitaler Inhalte, die Einstellung und den Abruf von Daten zur Verfügung steht.
      </p>

      <h3 class="s6">
        C)
      </h3>
      <p>
        In Fällen höherer Gewalt ist LDE von der Leistungspflicht befreit. Als höhere Gewalt gelten alle
        unvorhergesehenen Ereignisse sowie solche Ereignisse, deren Auswirkungen auf die Ver-tragserfüllung von keiner
        Partei zu vertreten sind. Ansprüche ergeben sich für die Nutzer bei nicht durch von LDE zu verantwortenden
        Ausfällen nicht.
      </p>

      <h3 class="s6">
        D)
      </h3>
      <p>
        Soweit Wartungsarbeiten erforderlich sind, werden diese nach 22 Uhr durchgeführt, sofern die Aufrechterhaltung
        des Betriebs keine frühere Wartung erfordert.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        13: Haftungsbeschränkung
      </h2>

      <h3 class="s6">
        A)
      </h3>
      <p>
        Gegenüber Kunden, die keine Verbraucher sind, haftet LDE für Schäden, außer im Fall der Verletzung wesentlicher
        Vertragspflichten, nur, wenn und soweit LDE, seinen gesetzlichen Ver-tretern, leitenden Angestellten oder
        sonstigen Erfüllungsgehilfen Vorsatz oder grobe Fahrlässig-keit zur Last fällt. Im Fall der Verletzung
        wesentlicher Vertragspflichten haftet LDE für jedes schuldhafte Verhalten seiner gesetzlichen Vertreter,
        leitender Angestellter oder sonstiger Erfül-lungsgehilfen.
      </p>

      <h3 class="s6">
        B)
      </h3>
      <p>
        Außer bei Vorsatz oder grober Fahrlässigkeit gesetzlicher Vertreter, leitender Angestellter oder sonstiger
        Erfüllungsgehilfen, ist die Haftung von LDE der Höhe nach auf die bei Vertrags-schluss typischerweise
        vorhersehbaren Schäden begrenzt.
      </p>

      <h3 class="s6">
        C)
      </h3>
      <p>
        Eine Haftung für den Ersatz mittelbarer Schäden, insbesondere für entgangenen Gewinn, besteht nur bei Vorsatz
        oder grober Fahrlässigkeit gesetzlicher Vertreter, leitender Angestellter oder sonstiger Erfüllungsgehilfen von
        LDE.
      </p>

      <h3 class="s6">
        D)
      </h3>
      <p>
        Die vorgenannten Haftungsausschlüsse und Beschränkungen gelten nicht für Schäden aus der Verletzung des Lebens,
        des Körpers oder der Gesundheit sowie im Fall zwingender gesetzli-cher Regelungen.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        14: Anwendbares Recht und Gerichtsstand
      </h2>

      <h3 class="s6">
        A)
      </h3>
      <p>
        Für das Vertragsverhältnis gilt ausschließlich das Recht der Bundesrepublik Deutschland. Die Anwendung des
        UN-Übereinkommens über Verträge über den Internationalen Warenverkauf vom 11. April 1998 ist ausgeschlossen.
      </p>

      <h3 class="s6">
        B)
      </h3>
      <p>Erfüllungsort ist der Sitz der Gesellschaft.</p>

      <h3 class="s6">
        C)
      </h3>
      <p>
        Als Gerichtsstand wird 77694 Kehl vereinbart, sofern es sich bei dem Nutzer um einen Kaufmann im Sinne des
        Handelsgesetzbuches, eine juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches
        Sondervermögen handelt. Gleiches gilt, wenn der Nutzer seinen Wohnsitz nach Vertragsschluss ins Ausland verlegt
        oder keinen allgemeinen Gerichtsstand in Deutschland hat. LDE ist auch berechtigt, am Sitz des Vertragspartners
        zu klagen.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        15: Nichteinbeziehung und Unwirksamkeit
      </h2>
      <p>
        Für den Fall der ganz oder teilweisen Nichteinbeziehung oder Unwirksamkeit dieser AGB gelten die gesetzlichen
        Bestimmungen des § 306 BGB.
      </p>
      <p><strong>Hinweise nach Art. 246c EGBGB</strong></p>
      <p>
        Der Vertragsschluss erfolgt, indem Sie uns über Internet, E-Mail, Telefon, Fax oder über sonstige
        Kommunikationswege ein verbindliches Angebot auf Abschluss eines Vertrages übermitteln und wir das
        Vertragsangebot ausdrücklich annehmen.
      </p>
      <p>
        Der vollständige Vertragstext wird von uns nicht gespeichert. Sie können die Vertragsdaten je nach Art des
        Übermittlungsweges vor dem Absenden über Ihr E-Mail-Programm bzw. die für den E-Mail-Versand genutzte
        Internetseite Ihres E-Mail-Anbieters ausdrucken oder speichern bzw. bei Nutzung unserer Internetseite an Ihrem
        Rechner ausdrucken oder speichern bzw. das an uns gesendete Fax ausdrucken/aufbewahren bzw. eine Kopie des an
        uns gesendeten Briefes spei-chern oder ausdrucken. Nach Zugang des Angebots auf Vertragsschluss senden wir Ihnen
        per E-Mail oder Fax eine Eingangsbestätigung mit den von ihm übermittelten Daten.
      </p>
      <p>
        Vor dem Absenden des Angebots können Sie etwaige Eingabefehler durch Korrektur des an uns gerichteten Schreibens
        bzw. der E-Mail oder durch Korrektur der Eingaben in den dafür vorgese-henen Eingabefeldern der Plattform
        PopLab.schule beheben.
      </p>
      <p>Die Vertragssprache ist deutsch.</p>
      <p>
        Online-Streitschlichtung gemäß Art. 14 Abs. 1 ODR-VO: Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit, die Sie hier finden:
        <a
          href="https://ec.europa.eu/consumers/odr/"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://ec.europa.eu/consumers/odr/
        </a>.
      </p>
      <p>
        Hinweis gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSGB): LDE ist nicht zur Teilnahme an einem
        Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle im Sinne des VSBG verpflichtet und wird an
        einem solchen Streitbeilegungsverfahren nicht teilnehmen.
      </p>
      <address>
        Kundendienst:<br />
        Unser Kundendienst für Fragen, Reklamationen und Beanstandungen steht Ihnen werktags von 8:00 Uhr bis 16:00 Uhr
        unter<br />
        Telefon: 07851 - 48 599-0<br />
        E-Mail: service@poplab.schule<br />
        zur Verfügung.
      </address>
    </div>
  </div>
</template>

<script>
import config from "@/config";

/**
 * Affiche la page des CGU de PopLab en allemand.
 */
export default {
  name: "CguPopLabDe",
  computed: {
    mailLdeDe() {
      return config.mailsDe.lde;
    },
  },
};
</script>

<style lang="scss"></style>
